import Splide from '@splidejs/splide';

document.addEventListener('turbo:load', () => {
  // public
  const publicCarouselConfig = {
    classes: {
      arrows: 'splide__arrows carousel__arrows',
      arrow: 'splide__arrow carousel__arrow',
      prev: 'splide__arrow--prev carousel__arrow--prev',
      next: 'splide__arrow--next carousel__arrow--next',
      pagination: 'splide__pagination carousel__pagination',
      page: 'splide__pagination__page carousel__pagination-button',
    },
    rewind: true,
    rewindByDrag: true,
    type: 'slide',
    width: '100%',
  };

  if (document.querySelector('.splide-home-carousel')) {
    const splide = new Splide('.splide-home-carousel', publicCarouselConfig).mount();

    if (splide.length <= splide.options.perPage)
      splide.options = {
        drag: false,
      };
  }

  // landing page
  const landingCarouselConfig = {
    classes: {
      arrows: 'splide__arrows carousel__arrows',
      arrow: 'splide__arrow carousel__arrow',
      prev: 'splide__arrow--prev carousel__arrow--prev',
      next: 'splide__arrow--next carousel__arrow--next',
      pagination: 'splide__pagination carousel__pagination',
      page: 'splide__pagination__page carousel__pagination-button',
    },
    fixedWidth: '100%',
    type: 'loop',
    snap: true,
    trimSpace: 'move',
    start: 0,
    gap: 'var(--space-8)',
    perMove: 1,
    perPage: 1,
    clones: 0,
    mediaQuery: 'min',
    breakpoints: {
      768: {
        perPage: 2,
        fixedWidth: 'var(--slide-max-width))',
      },
      1280: {
        perPage: 3,
      },
    },
  };

  if (document.querySelector('.splide-landing-carousel')) {
    const splide = new Splide('.splide-landing-carousel', landingCarouselConfig);
    const Components = splide.Components;

    splide.on('overflow', function (isOverflow) {
      splide.options = {
        arrows: isOverflow,
        pagination: isOverflow,
        drag: isOverflow,
        clones: isOverflow ? undefined : 0,
      };
    });

    splide.on('resized', function () {
      var isOverflow = Components.Layout.isOverflow();
      var list = Components.Elements.list;
      var lastSlide = Components.Slides.getAt(splide.length - 1);

      if (lastSlide) {
        // Toggles `justify-content: center`
        list.style.justifyContent = isOverflow ? '' : 'center';

        // Remove the last margin
        if (!isOverflow) {
          lastSlide.slide.style.marginRight = '';
        }
      }
    });

    splide.mount();
  }
});
